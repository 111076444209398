body {
  background-color: #E2D7C5 !important;
}
.app{
  padding: 3.8% 6.5%;
  padding-bottom: 0%;
  background-color: #702E18 !important;
}
.logo {
  width:13.3%;
  height: 13.3%;
  margin: 0%;
  margin-bottom: 0%;
}
.app .title {
  font-size: 97px;
  letter-spacing:10px; 
  color:#E2D7C5; 
  margin-top:9%;
  line-height: normal;
}
.app .gallery {
  margin-top:3%
}
.gallery--image {
  object-fit:cover; 
  height:570px; 
  width:96%; 
  margin:2%
}
.gallery--title {
  font-size:30px; 
  color:#E2D7C5; 
  margin:2%;
  padding-top:5%; 
  padding-bottom:3%;
  line-height: normal;

}
.gallery--description{
  font-size:13.5px; 
  color:#F1EBE2; 
  margin:2%; 
  padding-bottom:3%;
  line-height: 1.45;
}
.app .gallery--information {
  margin:12% 0%; 
  margin-bottom: 15%;
  font-size:59px;
  letter-spacing:6px;
  color:#E2D7C5;
  line-height: normal;

}
.founder--image {
  object-fit:cover; 
  height:399px; 
  width:100%
}
.founder {
  display:flex;
  align-content: center;
  align-items: center;
}
.founder--content {
  margin-left: 7%;
}
.water--image {
  object-fit:cover; 
  height:530px; 
  width:530px;
  margin-top: 2%;
}
.footer {
  background-color: #F1EBE2;
  padding:7%
}
.footer--content {
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.footer--text {
  font-size: 28px;
  color:#702E18;
  text-align: center;
}
.footer .footer--input {
  border-color:#702E18 !important;
  color:#702E18;
  box-sizing: border-box;
  outline: none;
  border-width:1px;
  border-radius: 45px;
  width:55%;
  min-width: 270px;
  height: 40px;
  margin-top: 5%;
  margin-bottom: 3%;
  padding-left:30px
}
.footer--button {
  text-align: center;
  background-color: #702E18;
  width: fit-content;
  padding: 8px 55px;
  color:#F1EBE2;
  font-family: spadeL;
  border-radius: 45px;
  outline: none;
  border: none;
  box-shadow: none;
  text-decoration: none;
  font-size: 15px;
}

.spadeEL {
  font-family: spadeEL
}
.spadeELI {
  font-family: spadeELI
}
.spadeL {
  font-family: spadeL
}
.canelaL {
  font-family: canelaL
}
.spadeLI {
  font-family: spadeLI
}
.spadeM {
  font-family: spadeM
}
.canelaM {
  font-family: canelaM
}

@font-face {
  font-family: spadeEL;
  src: url('./Fonts/Seravek/SeravekBasic-ExtraLight.ttf');
}

@font-face {
  font-family: spadeELI;
  src: url('./Fonts/Seravek/SeravekBasic-ExtraLightItalic.ttf');
}

@font-face {
  font-family: spadeL;
  src: url('./Fonts/Seravek/SeravekBasic-Light.ttf');
}

@font-face {
  font-family: spadeLI;
  src: url('./Fonts/Seravek/SeravekBasic-LightItalic.ttf');
}

@font-face {
  font-family: spadeM;
  src: url('./Fonts/Seravek/SeravekBasic-Medium.ttf');
}

@font-face {
  font-family: canelaL;
  src: url('./Fonts/Canela/CanelaDeck-Light-Web.woff');
}

@font-face {
  font-family: canelaM;
  src: url('./Fonts/Canela/CanelaDeck-Medium-Web.woff');
}

::-webkit-input-placeholder {
  font-size: 14px;
  font-family: spadeL;
  color:#702E18;
}

:-moz-placeholder { /* Firefox 18- */
  font-size: 14px;
  color:#702E18;
  font-family: spadeL;
}

::-moz-placeholder {  /* Firefox 19+ */
  font-size: 14px;
  color:#702E18;
  font-family: spadeL;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .app .title {
    font-size: 10vw;
    letter-spacing: 3.36px;
    margin-bottom: 0% ;
  }
  .app .gallery--information  {
    font-size: 38px;
    letter-spacing: 3.36px;
    margin-top: 40%;
    margin-bottom: 40%;
  }
  .gallery--title{
    margin-left:0px;
  }
  .gallery--description {
    font-size: 16.2px;
    margin-left:0px;
    margin-top:4%;
    margin-right:0px;
  }
  .water--image{
    width:98%;
    height:98%
  }
  .logo {
    width:50%;
    height: 50%;
    margin: 25%;
    margin-bottom: 40%;
  }
  .gallery--image {
    margin: 0%;
    width: 100%;
    height: 370px;
    margin-top: 30%;
  }
  .founder--content {
    margin-left: 0%;
  }
  .gallery--title {
    margin-top: 10%;
  }
  .footer {
    padding: 30% 0%
  }
  .footer--text  {
    font-size: 24px;
    margin: 0% 15%;
    margin-bottom: 15%
  }
  .footer .footer--input {
    margin-bottom: 15%
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .logo {
    width:30%;
    height: 30%;
    margin: 0%;
    margin-bottom: 20%;
  }
  .app .title {
    font-size: 58px;
    letter-spacing: 3.36px;
  }
  .gallery--image {
    margin: 0%;
    width: 100%;
    height: 300px;
    margin-top: 30%;
  }
  .gallery--description {
    font-size: 17px;
  }
  .app .gallery--information  {
    font-size: 50px;
    letter-spacing: 3.36px;
    margin-top: 40%;
    margin-bottom: 40%;
  }
  .founder--image {
    height:400px
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .app .title {
    font-size: 48px;
    letter-spacing: 3.36px;
  }
  .app .gallery--information  {
    font-size: 50px;
    letter-spacing: 3.36px;
    margin-top: 25%;
    margin-bottom: 25%;
  }
  .gallery--description {
    font-size: 16px;
  }
  .gallery--image {
    margin: 0%;
    width: 100%;
    height: 330px;
    margin-top: 30%;
  }
  .water--image{
    width:98%;
    height:98%
  }
  .logo {
    width:20%;
    height: 20%;
    margin: 0%;
    margin-bottom: 15%;
  }
} 

@media only screen and (max-width: 768px) and (orientation: landscape) {
  .founder--content {
    margin-left: 0px;
  }
}

/*  ipad */
@media only screen and (min-width: 768px) and (orientation:portrait) {
  .app .title {
    font-size: 70px;
    letter-spacing:10px; 
    color:#E2D7C5; 
    margin-top:45%;
    margin-bottom:15%;
  }
  .logo {
    width:25%;
    height:25%;
  }
  .app{
    padding: 5% 8%
  }
  .gallery .col-md-4 {
    padding-left: 2%;
    padding-right: 2%;
  }
  .gallery--image {
    height: 300px
  }
  .gallery--title {
    padding-top: 10%;
    padding-bottom: 8%;
  }
  .gallery--description {
    font-size:13.7px;
    line-height: 1.6;
  }
  .app .gallery--information{
    margin-top: 22%;
    margin-bottom: 20%
  }
  .water--image{
    height:342px; 
    width:342px;
  }
  .footer {
    padding-bottom: 10%;
  }
  .footer--text  {
    font-size: 22px;
    color:#702E18;
    text-align: center;
  }
} 

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .app .title {
    font-size: 97px;
    letter-spacing:10px; 
    color:#E2D7C5; 
    margin-top:10%;
    margin-bottom:10%;
  }

  .founder--image {
    height: 559px
  }

  .app .gallery--information  {
    margin:12% 0%; 
    margin-bottom: 15%;
    font-size:59.5px;
    letter-spacing:6px;
    color:#E2D7C5
  }
  .gallery--description {
    font-size: 14.3px;
    margin-left:0px
  }
  .water--image{
    height:542px; 
    width:542px;
  }
  .logo {
    width:13.3%;
    height: 13.3%;
    margin: 0%;
    margin-bottom: 0%;
  }
  .gallery--image {
    object-fit:cover; 
    height:570px; 
    width:98%; 
    margin:2%;
    margin-left:0px
  }
  .founder--content {
    margin-left: 7%;
  }
  .gallery--title {
    font-size:30px; 
    color:#E2D7C5; 
    margin:2%;
    padding-top:5%; 
    padding-bottom:3%;
    margin-left: 0px;
  }
  .footer {
    background-color: #F1EBE2;
    padding:7%
  }
  .footer--text  {
    font-size: 28px;
    color:#702E18;
    text-align: center;
  }
  .footer .footer--input {
    margin-bottom: 3%;
  }
  .founder {
    margin-bottom:10%
  }
} 

/* ipad pro */
@media only screen and (min-width: 992px) and (orientation:portrait){
  .logo {
    width:22%;
    height: 22%;
  }
  .app{
    padding: 5% 8%
  }
  .app .title{
    margin-top: 47%;
    margin-bottom: 27%;
  }
  .gallery--image {
    height: 357px
  }
  .gallery--title {
    padding-top: 10%;
    padding-bottom: 8%;
  }
  .gallery--description {
    font-size:15.7px;
    line-height: 1.6;
  }
  .app .gallery--information{
    margin-top: 22%;
    margin-bottom: 20%;
  }
  .water--image{
    height:442px; 
    width:442px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1500px) {
  .gallery--description {
    font-size: 16px;
    line-height:1.4
  }
  .gallery--image {
    object-fit:cover; 
    height:720px; 
    width:96%; 
    margin:2%
  }
}